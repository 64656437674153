import './styles.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {GamePage} from "./pages/Game/index.tsx";
import {Provider} from "react-redux";
import {store} from "./store/index.ts";
import {ScoreBoard} from "./pages/ScoreBoard/index.tsx";

export const App = () => {
    return (
        <>
            <meta name="viewport" content="width=device-width, initial-scale=1"/>
            <Provider store={store}>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<GamePage />}/>
                        <Route path="/scores" element={<ScoreBoard />}/>
                    </Routes>
                </BrowserRouter>
            </Provider>
        </>
    )
        ;
};
